var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', [_c('v-card-title', {
    staticClass: "text-h4 secondary primary--text"
  }, [_vm._v(" " + _vm._s(_vm.$t('users.edit.title')) + " ")]), _c('v-card-text', {
    staticClass: "pt-4"
  }, [_c('v-form', {
    ref: "editUserForm",
    attrs: {
      "id": "edit-user-form",
      "lazy-validation": ""
    },
    on: {
      "submit": function submit($event) {
        $event.preventDefault();
        return _vm.editUser.apply(null, arguments);
      }
    }
  }, [_c('user-form', {
    attrs: {
      "source": _vm.user.source
    },
    model: {
      value: _vm.userToEdit,
      callback: function callback($$v) {
        _vm.userToEdit = $$v;
      },
      expression: "userToEdit"
    }
  })], 1)], 1), _c('v-card-actions', [_c('v-btn', {
    staticClass: "rounded-lg",
    attrs: {
      "elevation": "0"
    },
    on: {
      "click": function click($event) {
        return _vm.$emit('close');
      }
    }
  }, [_vm._v(_vm._s(_vm.$t('form.action.cancel')))]), _c('v-spacer'), _c('v-btn', {
    staticClass: "rounded-lg",
    attrs: {
      "elevation": "0",
      "type": "submit",
      "form": "edit-user-form",
      "color": "primary",
      "loading": _vm.isLoading
    }
  }, [_c('span', {
    staticClass: "ml-2"
  }, [_vm._v(" " + _vm._s(_vm.$t('form.action.saveUser')) + " ")])])], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }