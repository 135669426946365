import { User, UserUpdate } from '@/api/types/user'

function userToDto(user: User): UserUpdate {
  return {
    id: user.id,
    username: user.username,
    email: user.email,
    firstName: user.firstName,
    lastName: user.lastName,
    locale: user.locale,
    phone: user.phone,
    mobile: user.mobile,
    enabled: user.enabled,
    roles: user.roles.map((role) => role.name),
  }
}

export { userToDto }
