
import { defineComponent, PropType, ref } from '@vue/composition-api'
import { mdiCheck } from '@mdi/js'

import UserForm from '@/views/admin/users/components/UserForm.vue'

import { useLocaleActions, useNotify } from '@/store'

import { User, UserUpdate } from '@/api/types/user'

import { usersApi } from '@/api'

import { ValidationForm } from '@/utils/types/validation'
import { userToDto } from '@/utils/userToDto'

export default defineComponent({
  name: 'AdminUserDetail',
  components: {
    UserForm,
  },
  props: {
    user: {
      type: Object as PropType<User>,
      required: true,
    },
  },
  setup: (props, { root, emit }) => {
    const { addNotification } = useNotify()
    const { updateUser, isLoading } = usersApi.useUpdateUser()

    const { setCurrentLocale } = useLocaleActions()
    const currentUser: User = root.$store.state.auth.currentUser

    const editUserForm = ref<ValidationForm | null>(null)

    const userToEdit = ref<UserUpdate>(userToDto(props.user))

    const source = ref<string>(props.user.source)

    const editUser = () => {
      if (editUserForm.value?.validate()) {
        if (currentUser.id === userToEdit.value.id && userToEdit.value.locale)
          setCurrentLocale(userToEdit.value.locale)

        const userFullName = `${userToEdit.value.firstName} ${userToEdit.value.lastName}`

        updateUser(props.user.id, userToEdit.value)
          .then((editedUser) => {
            addNotification({
              type: 'success',
              text: root.$t('users.update.success', {
                name: userFullName,
              }) as string,
            })
            emit('user-edited', editedUser)
            emit('close')
          })
          .catch((error) => {
            error.userMessage = root.$t('users.update.error', {
              name: userFullName,
            })
            throw error
          })
      }
    }

    return {
      icons: { mdiCheck },
      editUserForm,
      userToEdit,
      editUser,
      isLoading,
      source,
    }
  },
})
